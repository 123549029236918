import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable }  from "rxjs";
import { IMessage } from "../../interfaces/Message";
import { IConversation } from '../../interfaces/Conversation';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private apiUrlTest: string    = 'https://chat-test.carlocksystems.com:8081/rest-api';

  // For production.
  private apiUrl: string        = window.location.protocol + '//' + window.location.hostname + ':8081/rest-api';


  constructor(private http: HttpClient) { }

  // TODO: Interface aanmaken voor de login-details. Komt nog wel..

  // Create a conversation object.
  createConversation(userId: any): Observable<IConversation> {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.post<IConversation>(this.apiUrlTest + '/v2/createconversation&user_id=' + userId, userId);
    } else {
      return this.http.post<IConversation>(this.apiUrl + '/v2/createconversation&user_id=' + userId, userId);
    }
  }

  // Check whether the operator is still present.
  checkIfOperatorAvailable(operatorId: string) {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.get(this.apiUrlTest + '/v2/useravailable?user_id=' + operatorId);
    } else {
      return this.http.get(this.apiUrl + '/v2/useravailable?user_id=' + operatorId);
    }
  }

  // Used to check whether a user is logged in. This will (and has to be) changed to a JWT in the near future.
  isLoggedIn() {
    return sessionStorage.getItem('operator');
  }

}
