<div class="message__input block block--primary" [ngClass]="{'is-hidden': !checkIfUserIdSet()}">

<!--  <div class="message__is-typing" [class.is-visible]="isTyping">-->

<!--    <div class="span&#45;&#45;dark">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">&lt;!&ndash;! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. &ndash;&gt;<path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>-->
<!--    </div>-->
<!--  </div>-->

  <div *ngIf="!isUsernameSessionSet()" class="message__input__disclaimer js-start-chat">

    <span (click)="chatStartClick()">
      Click here to start chatting
    </span>
  </div>

  <div class="message__input__loader" [class.is-active]="isLoading()">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M222.7 32.15C227.7 49.08 218.1 66.9 201.1 71.94C121.8 95.55 64 169.1 64 255.1C64 362 149.1 447.1 256 447.1C362 447.1 448 362 448 255.1C448 169.1 390.2 95.55 310.9 71.94C293.9 66.9 284.3 49.08 289.3 32.15C294.4 15.21 312.2 5.562 329.1 10.6C434.9 42.07 512 139.1 512 255.1C512 397.4 397.4 511.1 256 511.1C114.6 511.1 0 397.4 0 255.1C0 139.1 77.15 42.07 182.9 10.6C199.8 5.562 217.6 15.21 222.7 32.15V32.15z"/></svg>
  </div>

  <form [formGroup]="messageForm" (ngSubmit)="addNewMessage($event)">

    <input type="hidden" formControlName="conversationId">

    <div class="form-item">
      <label>
        Message
      </label>

      <input
        type="text"
        formControlName="message"
        id="message"
        (blur)="checkMessageInputs()"
        (focus)="checkMessageInputs()"
        (keyup)="checkMessageInputs()"
        autocomplete="off"
        class="js-text-input"
      >
    </div>

    <div class="form-item hidden">
      <label>
        Username
      </label>

      <input type="text" formControlName="username" class="'js-current-user">

    </div>

    <div class="form-item hidden">
      <label>
        Time
      </label>

<!--      <input type="text" formControlName="timePosted">-->
    </div>

    <div class="flex justify-space-between align-items-center message-input__options">
      <button
        type="submit"
        class="button button-primary svg-right message__send"
        id="sendMessage"
        [class.hidden]="!textInputValidated"
      >
        Send
        <i class="fas fa-paper-plane"></i>
      </button>


        <ng-container *ngIf="isOperator && checkIfConvoIsActive()">
            <div class="button button-secondary saved-responses js-saved-responses mr-3 ml-auto svg-left" >
              <i class="fas fa-comment"></i>

              Saved Responses
            </div>

            <app-saved-responses (fillSavedResponse)="fillSavedResponseFromChild($event)"></app-saved-responses>

        </ng-container>

      <div class="button button-secondary js-toggle-action-menu svg-left">
        <i class="fas fa-bars"></i>
        Options
        </div>

    </div>

  </form>
</div>
