import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { TranslationService } from "../../services/translation/translation.service";
import { MessagesService } from "../../services/messages/messages.service";
import {io} from "socket.io-client";
import { SocketService } from "../../services/socket/socket.service";

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {

  public files: File[] = [];

  constructor(
    private router: Router,
    private translationService: TranslationService,
    private messagesService: MessagesService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {
    this.toggleActionMenu();
    this.toggleImageUpload();
    this.onDragEnterFileDrop();

    setTimeout(() => {
      this.setUserInfo();
    }, 350);
  }

  toggleActionMenu(): void {

    let openActionMenu      = document.querySelectorAll('.js-toggle-action-menu');
    let closeActionMenu     = document.querySelectorAll('.js-close-action-menu');
    let actionMenu          = document.querySelector('.js-action-menu');
    let actionMenuOverlay   = document.querySelector('.js-action-menu-overlay');

    for (let i = 0; i < openActionMenu.length; i++) {

      openActionMenu[i].addEventListener('click', () => {
        actionMenu?.classList.add('is-active');
      })
    }

    for (let i = 0; i < closeActionMenu.length; i++) {

      closeActionMenu[i].addEventListener('click', () => {
        actionMenu?.classList.remove('is-active');
      })
    }

    actionMenuOverlay?.addEventListener('click', () => {
      actionMenu?.classList.remove('is-active');
    });
  }

  onCloseChat(): void {

    // Clear session.
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('conversation_id');
    sessionStorage.removeItem('username');

    // Navigate back to index (For the future: close the chat-window).
    this.router.navigate(['/']);
  }

  /**
   * TRANSLATE SHIZZLE
   */

  onTranslateMessages(): void {

    let messageContent = document.querySelectorAll('.js-message-content');
    let messageArray = [];

    if (messageContent) {
      for (let i = 0; i < messageContent.length; i++) {

        messageArray.push(messageContent[i].textContent);

        this.translationService.translateText(messageArray, 'en').subscribe((response: any) => {
          let translatedText = response.data.translations[i].translatedText;

          messageContent[i].replaceWith(response.data.translations[i].translatedText);
        });
      }

    }
  }

  /**
   * IMAGE UPLOAD SPECIFIC FUNCTIONS.
   */

  onSelect(event: any) {

    let imageSizeLimit = 1000000; // = 1MB

    for (let i = 0; i < event.addedFiles.length; i++) {

      if(event.addedFiles[i].size <= imageSizeLimit) {
        this.files.push(event.addedFiles[i]);
      } else {
        alert('The image(s) that you tried to upload seem(s) to be too large. Please upload images that are smaller than 1MB.');
        return;
      }
    }

    return;
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onFileUploaded(event: any): void {

    /*
     * This function is responsible for handling image uploading (and sending).
     * This is especially useful for when clients need to send photos of keys etc.
     */

    // Socket connection settings.
    const socket = this.socketService;


    let imageUploader           = document.querySelector('.js-image-upload');
    let imageUploaderOverlay    = document.querySelector('.js-image-uploader');
    let imageUploaderDrag       = document.querySelector('.js-image-uploader-drag');
    let imageUploadOverlayDrag         = document.querySelector('.js-image-upload-drag');


    // Only execute the function if an image is actually uploaded.
    if (this.files) {

      // Loop through all the selected files and create a new message of each photo.
      for (let i = 0; i < this.files.length; i++) {

        // Variable for the FileReader (is needed to display and post the image.
        let reader = new FileReader();
        let file = this.files[i];
        let latestDate  = new Date();
        let getHours    = ('0' + latestDate.getHours()).slice(-2);
        let getMinutes  = ('0' + latestDate.getMinutes()).slice(-2);

        // When images are uploaded, but first check the file type.
        if(file.type == 'image/jpg' || file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'application/pdf') {

          reader.onload = () => {


            // Message body settings that need to be sent along with the image.
            let messageDetails = {
              conversation_id: sessionStorage.getItem('conversation_id'),
              user_id: sessionStorage.getItem('user_id'),
              base64: reader.result,
              username: sessionStorage.getItem('username')
            }

            // Emit the message through Socket.io.
            socket.addMessage(messageDetails);
            // this.displayMessage(messageDetails);
            socket.displayMessage(messageDetails);

            // TODO: Call naar BE.
            this.messagesService.sendPhoto(messageDetails).subscribe(() => {

              let activeConvoId = sessionStorage.getItem('conversation_id');
              let activeConvo = document.querySelector(`.js-conversation[data-conversation-id='${activeConvoId}']`);

              // @ts-ignore
              activeConvo.click();
              this.socketService.scrollToBottom();
            });

            // Lastly, reset the image uploader.
            this.onRemove(this.files);
            imageUploader?.classList.remove('is-active');
            imageUploaderOverlay?.classList.remove('is-active');

            imageUploaderDrag?.classList.remove('is-active');
            imageUploadOverlayDrag?.classList.remove('is-active');
          }

          // Needed in order to display the uploaded file.
          reader.readAsDataURL(file);
        } else {

          alert(`The uploaded file is not supported. Please check if the file you're trying to upload is a JPG, JPEG, PNG or PDF-file.`);
          return;
        }

      }

    }

    // 6. Reset functions after an image is uploaded.
    // this.loadUserSettings();

  }

  public toggleImageUpload(): void {

    let imageUploadButton       = document.querySelectorAll('.js-toggle-file-upload');
    let imageUploadOverlay      = document.querySelector('.js-image-uploader');
    let imageUploader           = document.querySelector('.js-image-upload');

    for (let i = 0; i < imageUploadButton.length; i++) {
      imageUploadButton[i].addEventListener('click', () => {
        imageUploadOverlay!.classList.toggle('is-active');
        imageUploader!.classList.toggle('is-active');
      });
    }

    // Should probably go in another function, but since it is such a minor thing..
    // Toggle a default class of 'hidden' on the image-upload button to prevent any unnecessary events.

    let imageUploadSendButton = document.querySelector('.js-upload-image-button');

    imageUploadSendButton?.classList.add('hidden');
  }

  public toggleImageUploadButton(): void {
    let imageUploadButton = document.querySelector('.js-upload-image-button');

    if (this.files) {
      imageUploadButton?.classList.remove('hidden');
    } else {
      imageUploadButton?.classList.add('hidden');
    }
  }


  public checkIfOperator() {

    let operator = sessionStorage.getItem('operator_name');

    if(operator) {
      return true;
    } else {
      return false;
    }
  }

  public setUserInfo(): void {

    if(sessionStorage.getItem('operator_name')) {
      let username = sessionStorage.getItem('operator_name');
      document.querySelector('.js-username').innerHTML += username;
    }


  }


  async startCapture() {

    let displayMediaOptions = {
      video: {
        cursor: "always"
      },
      audio: false
    };


    // @ts-ignore
    let video = await window.parent.navigator.mediaDevices.getDisplayMedia(displayMediaOptions);


    let messageDetails = {
      // @ts-ignore
      conversation_id: sessionStorage.getItem('conversation_id'),
      user_id: sessionStorage.getItem('user_id'),
      username: sessionStorage.getItem('username'),
      video: video
    }

    // Emit the message through Socket.IO
    this.socketService.addMessage(messageDetails);

    // Show the message to the operator (HIGHLY EXPERIMENTAL).
    this.socketService.displayMessage(messageDetails);

    // Test.
    setTimeout(() => {
      let videoStream = document.getElementById('videoStream');

      // @ts-ignore
      videoStream.srcObject = video;
    }, 1500);

    console.log(messageDetails);
  }

  public onDragEnterFileDrop() {

    let imageUploaderDrag   = document.querySelector('.js-image-uploader-drag');
    let imageUploader       = document.querySelector('.js-image-upload-drag');
    let body                = document.querySelector('body');
    let counter             = 0;

    body.addEventListener('dragenter', (e) => {
      counter++;
      imageUploaderDrag.classList.add('is-active');
      imageUploader.classList.add('is-active');
    });

    body.addEventListener('dragleave', () => {
      counter--;

      if(counter === 0) {
        imageUploaderDrag.classList.remove('is-active');
        imageUploader.classList.remove('is-active');
      }
    });

  }
}
