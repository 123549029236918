import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';


import { MessageInputComponent } from './components/message-input/message-input.component';
import { AppComponent } from './app.component';
import { ChatwindowComponent } from './components/chatwindow/chatwindow.component';
import { LoginComponent } from './components/login/login.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { OperatorDashboardComponent } from './components/operator-dashboard/operator-dashboard.component';
import { InitChatComponent } from './components/init-chat/init-chat.component';
import { ChatIconComponent } from './components/chat-icon/chat-icon.component';
import { SafePipe } from "./pipes/safe/safe.pipe";
import { SavedResponsesComponent } from './components/message-input/saved-responses/saved-responses.component';

const config: SocketIoConfig = { url: 'http://localhost:3001', options: {transports: ['websocket']} };


@NgModule({ declarations: [
        AppComponent,
        ChatwindowComponent,
        MessageInputComponent,
        LoginComponent,
        ActionMenuComponent,
        OperatorDashboardComponent,
        InitChatComponent,
        ChatIconComponent,
        SafePipe,
        SavedResponsesComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        RouterModule,
        NgxDropzoneModule,
        SocketIoModule.forRoot(config)], providers: [
        LoginComponent,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
