import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessagesService } from "../../../services/messages/messages.service";
import { FormGroup, FormControl } from '@angular/forms';
import {Observable} from "rxjs";


@Component({
  selector: 'app-saved-responses',
  templateUrl: './saved-responses.component.html',
  styleUrls: ['./saved-responses.component.scss']
})
export class SavedResponsesComponent implements OnInit {

  public language: string                 = sessionStorage.getItem('language');
  public operator: string                 = sessionStorage.getItem('operator');
  public preformedTexts;
  public showPreformedTextMenu: boolean   = false;

  private getMessages$: Observable<any> = this.messagesService.getMessages(this.operator);

  @Output() fillSavedResponse = new EventEmitter<string>();

  constructor(
    private messagesService: MessagesService
  ) { }


  ngOnInit(): void {
    setTimeout(() => {
      this.setPreformedTextsVariable(sessionStorage.getItem('language'));
    }, 250);
    this.togglePreformedTextMenu();

    this.getMessages$.subscribe((data) => {

      if(data) {

        setTimeout(() => {
          this.togglePreformedTextLanguage();
        }, 250);
      }
    });
  }

  public togglePreformedTextLanguage() {

    // Set the preformed texts when clicking on a conversation.
    let conversations = document.querySelectorAll('.js-conversation');

    conversations.forEach((conversation) => {

      conversation.addEventListener('click', () => {
        let language = conversation.getAttribute('data-language');

        this.setPreformedTextsVariable(language);
      });
    });
  }

  public setPreformedTextsVariable(language: string): void {

    // Set the preformed texts on load.
    this.messagesService.getPreformedTexts(language).subscribe((data) => {
      this.preformedTexts = data;
    });
  }


  public setPreformedText(id: number) {

    let preformedText   = document.querySelector(`span.preformed-text-${id}`).textContent;

    this.fillSavedResponse.emit(preformedText);
  }


  public togglePreformedTextMenu() {

    let toggleButton      = document.querySelector('.js-saved-responses');

    toggleButton.addEventListener('click', () => {
      this.showPreformedTextMenu = true;
    });
  }


  public closeActionMenu() {
    this.showPreformedTextMenu = false;
    return;
  }
}
