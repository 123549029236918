import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MessagesService } from "../../services/messages/messages.service";
import { TranslationService } from "../../services/translation/translation.service";
import { SocketService } from "../../services/socket/socket.service";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: 'app-operator-dashboard',
  templateUrl: './operator-dashboard.component.html',
  styleUrls: ['./operator-dashboard.component.scss']
})

export class OperatorDashboardComponent implements OnInit {

  public chatTitle: string            = 'CLS Chat';
  public chatSubTitle: string        = 'V 1.7.10';
  public conversations: any[]         = [];
  public operatorMessages: any[]      = [];
  public archivedChatsShown: boolean  = false;
  private isWhatsAppConvo: boolean;

  constructor(
    private router: Router,
    private messagesService: MessagesService,
    private socketService: SocketService
  ) { }

  ngOnInit(): void {

    // Transform cookies into session storages.
    const getCookie = (cookie_name) =>{
      // Construct a RegExp object as to include the variable name
      const re = new RegExp(`(?<=${cookie_name}=)[^;]*`);
      try{
        return document.cookie.match(re)[0];	// Will raise TypeError if cookie is not found
      }catch{
        return "this-cookie-doesn't-exist";
      }
    }

    let operatorId      = getCookie('operator_id_from_intranet');
    let operatorName    = getCookie('operator_name');

    if(window.location.hostname != 'localhost') {
      if(this.router.url === '/admin') {
        sessionStorage.setItem('user_id', operatorId);
        sessionStorage.setItem('username', operatorName);
        sessionStorage.setItem('operator', operatorId);
        sessionStorage.setItem('operator_name', operatorName);
      }
    }

    // Declare variables.
    const socket        = this.socketService;
    let conversationId  = sessionStorage.getItem('conversation_id');


    // If there was a session for the conversation active, it will reload this conversation upon browser refresh.
    if(conversationId) {
      this.loadConversation(conversationId);
    }

    // Init main functions.
    this.calculateHeightChatWindow();
    this.onOpenExistingConversation();
    this.getAllConversations();
    this.insertDatesOfMessages();

    // Init functions on timeout.
    setTimeout(() => {
      this.calculateHeightChatWindow();
      this.enlargeImageSocket();
      this.removeNewMessageClassSocket();
      this.countNotifications();
    }, 500);


    // Init functions on resize.
    window.addEventListener('resize', () => {
      this.calculateHeightChatWindow();
    });

    // Init poll functions here.
    setInterval(() => {
      // console.log('POLLING');
      this.getAllConversations();
    }, 5000);


    // Socket events.
    socket.getMessage('add-message').subscribe((messageDetails) => {

      let currentUser = sessionStorage.getItem('user_id');

      if(this.router.url === '/admin') {
        this.socketService.displayMessageOperator(messageDetails);
        this.updateConversationTab(messageDetails);
        this.removeNewMessageClassSocket();
        this.enlargeImageSocket();

        setTimeout(() => {
          if(currentUser != messageDetails.username) {
            this.notifyOperator(messageDetails.conversation_id);
            this.countNotifications();
          }

        }, 500);

        // Only show the notification to the destined operator.
        if(currentUser == messageDetails.operator) {
          this.newMessageNotification(messageDetails.username, messageDetails.text);
        }
      }
    });

  }

  public insertDatesOfMessages(): void {
    /**
     * This function makes it clear for the operator to see when messages are sent, by adding a date above the messages.
     * This is done by putting a date above the messages that correspond with that same date (just like WhatsApp/Telegram).
     */

    // 1 - Declare base variables.
    let operatorId              = sessionStorage.getItem('operator');
    let messageDatesArray: any  = [];

    // 2 - Execute the following code block once the required messages are loaded.
    this.messagesService.getMessages(operatorId).subscribe(data => {

      // 2.1 - If the message-date div is already present, remove them first to prevent duplicate code.
      if(document.querySelector('.message__date')) {
        let messageDates = document.querySelectorAll('.message__date');

        for(let i =0; i < messageDates.length; i++) {
          messageDates[i].remove();
        }
      }

      // 3 - Main stuff.
      setTimeout(() => {

        // 3.1 - Declare variables.
        let messageDates = document.querySelectorAll('.js-message');

        // 3.2 - Get the dates of all messages and store them in an array.
        for (let i = 0; i < messageDates.length; i++) {
          messageDatesArray.push(messageDates[i].getAttribute('date'));
        }

        // 3.4 - We filter the duplicate dates out in the function below.
        let messageDatesUnique  = [...new Set(messageDatesArray)];

        // 3.5 - Next, we create the div with the date for every given date from the array above.
        for(let i = 0; i < messageDatesUnique.length; i++) {

          // 3.5.1 Declare variables.
          let firstMessageWithDate    = document.querySelector(`.js-message[date="${messageDatesUnique[i]}"]`);
          let dateDiv                 = document.createElement('div');

          // 3.5.2 - Add corresponding SCSS-classes.
          dateDiv.classList.add(`message__date`);
          dateDiv.innerHTML = `<span class="span--dark">${messageDatesUnique[i]}</span>`;


          // 3.5.3 - After that, we append the div to the conversation.
          //         This is appended right before every first message-item with a new/unique date.
          firstMessageWithDate?.before(dateDiv);

          // 3.5.4 - Then, we scroll down to the latest messages.
          this.scrollToBottom();

          // 3.5.5 - And lastly, we remove the loader.
          document.querySelector('.message__input').classList.remove('is-hidden');
          document.querySelector('.conversation--loading').classList.remove('is-active');
        }
      }, 250);
    })
  }


  public activateRightConversation(conversationId: number): void {

    this.messagesService.getMessage(conversationId).subscribe(() => {

      setTimeout(() => {
        document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`).classList?.add('is-active');
        document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`).classList?.remove('has-delete-active');
      }, 50);
    });
  }

  public newMessageNotification(client: string, text: string): void {

    let notificationSound = new Audio('./assets/notification.mp3');

    (async () => {
      // create and show the notification
      const showNotification = () => {
        // create a new notification
        const notification = new Notification(`Nieuw bericht van ${client}`, {
          body: text.substring(0, 30),
          icon: './assets/images/cls.jpg',
          requireInteraction: true
        });


        // Play notification sound.
        notificationSound.play();

        // close the notification on click.
        notification.addEventListener('click', () => {
          notification.close();
        });
      }

      // show an error message
      const showError = () => {
        const error = document.querySelector('.error');
        // @ts-ignore
        error.style.display = 'block';
        error.textContent = 'You blocked the notifications';
      }

      // check notification permission
      let granted = false;

      if (Notification.permission === 'granted') {
        granted = true;
      } else if (Notification.permission !== 'denied') {
        let permission = await Notification.requestPermission();
        granted = permission === 'granted';
      }

      // show notification or error
      granted ? showNotification() : showError();
    })();
  }

  public ifMessageIsSelected() {

    return sessionStorage.getItem('conversation_id');

  }


  public notifyOperator(conversationId: number): void {

    /**
     * Adds a notification class to the conversation tab, so you know when a client sends in a new message.
     */

    if(this.router.url == '/admin') {
      if(document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`)) {
        document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`).classList.add('new-message');
      }
    }
  }


  public loadConversation(conversationId): void {

    /**
     * This function loads the messages from the last opened conversation.
     * This means that the session should already have been set.
     * If not, nothing will load.
     */



      this.messagesService.getMessage(conversationId).subscribe((data) => {

        this.operatorMessages   = data.MessageList;

        let conversation_id     = sessionStorage.getItem('conversation_id');

        this.chatTitle          = data.Conversation.company_name;
        this.chatSubTitle       = data.Conversation.subject;

        document.querySelector('.js-messages-loop.is-operator-view').classList.add('is-active');

        setTimeout(() => {
          document.querySelector(`.js-conversation[data-conversation-id="${conversation_id}"]`)?.classList.add('is-active');
          this.socketService.scrollToBottom();

          if(sessionStorage.getItem('is-whatsapp')) {
            let activeConvoId = sessionStorage.getItem('conversation_id');
            let activeConvo = document.querySelector(`.js-conversation[data-conversation-id='${activeConvoId}']`);

            // @ts-ignore
            activeConvo.click();
            this.socketService.scrollToBottom();
            document.querySelector('body').classList.add('whatsapp-convo-is-active');
          } else {
            if(document.querySelector('body').classList.contains('whatsapp-convo-is-active')) {
              document.querySelector('body').classList.remove('whatsapp-convo-is-active');
            }
          }
        }, 500);
      });
  }


  public calculateHeightChatWindow(): void {

    /**
     * Makes sure that the operator's dashboard is always the same (full) height of the browser on init.
     */

    let messageLoop             = document.querySelector('#messages-loop') as HTMLElement;
    let chatWindowHead          = document.querySelector('#chatWindowHead') as HTMLElement;
    let chatWindowMessageInput  = document.querySelector('#chatWindowMessageInput') as HTMLElement;
    let windowHeight            = window.innerHeight;

    setTimeout(() => {
        messageLoop.style.height = `calc(100vh - ${chatWindowHead.offsetHeight + (chatWindowMessageInput.offsetHeight + 30)}px)`;
      }, 50);
  }


  public setMessageOnRead(conversationId: number) {

    let trueConversationId = {
      conversation_id: conversationId
    }

    this.messagesService.setMessageOnRead(trueConversationId);
  }

  public getAllConversations(): void {

    /**
     * This retrieves the conversation data for the conversation tabs.
     */

    let operatorId      = sessionStorage.getItem('user_id');

    this.messagesService.getMessages(operatorId).subscribe( (data) => {
      this.conversations = data.ConversationList;

    });
  }


  public switchConversation(conversationId: number) {

    /**
     * Get a specific conversation by ID.
     * This function also includes the (duration of) the loading animation.
     */

    document.querySelector('.js-conversation-loading').classList.add('is-active');
    document.querySelector('.js-messages-loop.is-operator-view').classList.add('is-active');

    /**
     * Remove the message that is emitted through the websocket.
     * This makes sure that there are no duplicate messages and that the right messages are shown
     * when switching to another conversation.
     */

    setTimeout(() => {
      let socketMessages = document.querySelectorAll('.is-from-socket');

      for (let i = 0; i < socketMessages.length; i++) {

        socketMessages[i].remove();
      }
    }, 150);
  }


  public onOpenExistingConversation(): void {

    /**
     * This functions handles the logic for when you click on a conversation tab in
     * the operator dashboard.
     * This part is only for when an existing conversation is opened.
     */

    document.addEventListener('click', (e) => {

      // @ts-ignore
      let target = e.target.closest('.js-conversation');

      if(target) {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
        }

        // @ts-ignore
        let conversationClass = e.target.classList;
        // @ts-ignore
        let conversationId  = e.target.getAttribute("data-conversation-id");

        if(conversationClass.contains('has-phone-number')) {
          this.isWhatsAppConvo = true;
          sessionStorage.setItem('is-whatsapp', 'true');
          document.querySelector('body').classList.add('whatsapp-convo-is-active');
        } else {
          this.isWhatsAppConvo = false;
          sessionStorage.removeItem('is-whatsapp');
          document.querySelector('body').classList.remove('whatsapp-convo-is-active');

        }

        if(this.isWhatsAppConvo) {

          setInterval(() => {
            document.querySelector('.js-message.is-from-socket').remove();

            this.messagesService.getMessage(conversationId).subscribe((data) => {

              console.log('Message retrieved.');

              this.operatorMessages   = data.MessageList;

              this.chatTitle          = data.Conversation.company_name;
              this.chatSubTitle       = data.Conversation.subject;

              sessionStorage.setItem('client_id', data.Conversation.customer_user_id);
              sessionStorage.setItem('language', data.Conversation.language);

              // Remove the loader, if it wasn't removed already.
              setTimeout(() => {
                document.querySelector('.js-conversation-loading').classList.remove('is-active');
              }, 650);

              let messages = data.MessageList;

              for (let i = 0; i < messages.length; i++) {

                // If the message(s) match the current session user ID, they will get a class that shows what messages belong to who.
                if(messages[i].user_id == sessionStorage.getItem('user_id')) {
                  messages[i].is_current_user = true;
                }
              }
            });

            this.insertDatesOfMessages();
          }, 30000);
        } else {

          // Get a reference to the last interval + 1
          const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

          // Clear any timeout/interval up to that id
          for (let i = 1; i < interval_id; i++) {
            window.clearInterval(i);
          }

          // console.log('POLL ENDED');

        }

        if(conversationClass.contains('js-conversation')) {


          // @ts-ignore
          if(e.target.getAttribute("data-conversation-id") != undefined) {


            // @ts-ignore
            let conversationId  = e.target.getAttribute("data-conversation-id");

            // Set the right conversation as the current conversation.
            sessionStorage.setItem('conversation_id', conversationId);

            this.switchConversation(conversationId);

            let conversations = document.querySelectorAll('.js-conversation');


            for(let i = 0; i < conversations.length; i++) {
              conversations[i].classList.remove('is-active');
            }

            conversationClass.add('is-active');

            // Retrieve the right message-feed.
            this.messagesService.getMessage(conversationId).subscribe({next:(data) => {

              this.operatorMessages   = data.MessageList;
              this.chatTitle          = data.Conversation.company_name;
              this.chatSubTitle       = data.Conversation.subject;

              sessionStorage.setItem('client_id', data.Conversation.customer_user_id);
              sessionStorage.setItem('language', data.Conversation.language);

              // Remove the loader, if it wasn't removed already.
              setTimeout(() => {
                document.querySelector('.js-conversation-loading').classList.remove('is-active');
              }, 650);

              let messages = data.MessageList;

              for (let i = 0; i < messages?.length; i++) {

                // If the message(s) match the current session user ID, they will get a class that shows what messages belong to who.
                if(messages[i].user_id == sessionStorage.getItem('user_id')) {
                  messages[i].is_current_user = true;
                }
              }
            }});
          }
        }
      }
    });

  }


  public updateConversationTab(messageDetails: any): void {
    /**
     * This function adds the conversation tab on the operator's dashboard through Socket.IO.
     */


    if(this.router.url == '/admin') {

      if(messageDetails.company_name != undefined) {
        let operatorConversations = document.querySelector('.js-conversations');

        let conversationItem =
          `
      <div
        class="conversation js-conversation"
        data-subject-id="${messageDetails.subject}"
        data-conversation-id="${messageDetails.conversation_id}"
        data-language="${messageDetails.language}"
        data-operator-id="${ messageDetails.operator_user_id }"
      >

          <div class="conversation__flag">
            <div class="fi-${messageDetails.language} fis"></div>
          </div>

          <div class="conversation__details">

            <div class="flex flex-wrap">
              <div class="conversation__client-name">
                ${messageDetails.company_name}
              </div>

              <span class="conversation__last-message">
                ${messageDetails.translated_text}
              </span>
            </div>
              <span class="conversation__last-message">
                ${messageDetails.timePosted}
              </span>
            </div>

            <div class="conversation__new_message js-new-message">
              <i class="fas fa-comment"></i>
            </div>
          </div>
        </div>
      `;


        // Show message in the operator dashboard.


        if(sessionStorage.getItem('operator') == messageDetails.operator) {

          if ( !operatorConversations.querySelector(`.js-conversation[data-conversation-id="${messageDetails.conversation_id}"] `) ) {

            operatorConversations.innerHTML += conversationItem;
          } else {

            document
              .querySelector(`.js-conversation[data-conversation-id="${messageDetails.conversation_id}"] .conversation__last-message`)
              .innerHTML = messageDetails.translated_text;
          }
        }
      }
    }
  }


  public closeConversation(conversation_id: number): void {

    /**
     * This function contains the logic for when an operator closes a conversation.
     * Will be build in the near future.
     */

    this.messagesService.closeConversation(conversation_id);
    document.querySelector(`.js-conversation[data-conversation-id='${conversation_id}']`).remove();

  }

  public toggleDeleteClass(conversation_id: number) {

    let conversations = document.querySelectorAll(`.js-conversation`);

    for(let i = 0; i < conversations.length; i++) {
      conversations[i].classList.remove('has-delete-active');
    }

    document.querySelector(`.js-conversation[data-conversation-id='${conversation_id}']`).classList.add('has-delete-active');
  }


  public removeNewMessageClass(conversationId: number) {

    /**
     * Remove the 'new-message' class from the conversation when clicked on.
     */

    document.querySelector(`.js-conversation[data-conversation-id='${conversationId}']`).classList.remove('new-message');
    document.querySelector(`.js-conversation[data-conversation-id='${conversationId}']`).removeAttribute('data-unread_message');

    // Re-init the notification count.
    this.countNotifications();

  //  And also add the dates.
    this.insertDatesOfMessages();
  }


  public removeNewMessageClassSocket(): void {

    /**
     * Same function as the one above, but this one is for conversations created and emitted through Socket.IO
     */

    if(document.querySelector('.js-conversation')) {

      let conversation = document.querySelector('.js-conversation');
      let messageInput =  document.querySelector('input#message');

      conversation.addEventListener('click', (event) => {

        // @ts-ignore
        let conversationId  = event.target.getAttribute('data-conversation-id');

        document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`).classList.remove('new-message');

        // Reset the notification count as well.
        this.countNotifications();

      });

      messageInput.addEventListener('click', (event) => {

        // @ts-ignore
        let conversationId  = sessionStorage.getItem('conversation_id');

        document.querySelector(`.js-conversation[data-conversation-id="${conversationId}"]`)?.classList.remove('new-message');

        // Reset the notification count as well.
        this.countNotifications();

      });

    }

  }


  /**
   * Other functions
   */

  public scrollToBottom(): void {

    /**
     * Scroll to the bottom of the message feed. Multipurpose.
     */

    let bottom = document.getElementById('messages-loop')!;

    bottom.scrollTop = bottom.scrollHeight;
  }


  public matchMessageWithUser() {

    /**
     * Used for giving a specific class to the messages, so you know what messages belong to
     * you as client and the operator.
     */

    let sessionUsername   = sessionStorage.getItem('operator_name');

    return sessionUsername;
  }

  public toggleArchivedConversations() {

    /**
     * Show/hide the archived conversations.
     */

    let openConversations     = document.querySelectorAll('.js-conversation:not(.is-closed)');
    let closedConversations   = document.querySelectorAll('.js-conversation.is-closed');

    // For the open conversations.
    for(let i = 0; i < openConversations.length; i++) {
      openConversations[i].classList.toggle('is-closed');
    }

    // For the closed conversations.
    for(let i = 0; i < closedConversations.length; i++) {
      closedConversations[i].classList.toggle('is-closed');
    }

    // Toggle the arhivedChatsShown variable to show the right label on the toggle-button.
    this.archivedChatsShown = !this.archivedChatsShown;
  }


  public enlargeImage(event): void {

    /**
     * Used for enlarging images when you click on them.
     */

    let imageSrc  = event.composedPath()[0].currentSrc;
    let win       = window.open();
    let iFrame    = '<iframe src="' + imageSrc + '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>';

    win?.document.write(iFrame);

  }


  public enlargeImageSocket(): void {


    /**
     * Same function as the one above, but this one is used for the messages that are emitted through Socket.IO.
     * The function above only works for the images that are retrieved from the getMessages-call.
     */

    document.addEventListener('click', (event) => {

      // @ts-ignore
      let targetImage = event.target.classList;


      if(targetImage.contains('image-is-from-socket')) {

        // @ts-ignore
        let imageSrc = event.composedPath()[0].currentSrc;
        let win       = window.open();
        let iFrame    = '<iframe src="' + imageSrc + '" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>';

        win?.document.write(iFrame);
        return;
      }
    });

  }

  public countNotifications() {

    let countNewMessages = document.querySelectorAll('.new-message').length;

    if(countNewMessages > 0) {
      document.title = `(${countNewMessages}) Chatbox`;
    } else {
      document.title = 'Chatbox';
    }
  }

}


