import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatwindowComponent } from "./components/chatwindow/chatwindow.component";
import { LoginComponent } from "./components/login/login.component";
import { OperatorDashboardComponent } from "./components/operator-dashboard/operator-dashboard.component";
import { InitChatComponent } from "./components/init-chat/init-chat.component";
import {AuthGuard} from "./services/auth/auth.guard";

const routes: Routes = [
  {path: "", component: LoginComponent},
  {path: "chat", component: ChatwindowComponent},
  {path: "admin", component: OperatorDashboardComponent},
  {path: "init-chat", component: InitChatComponent},
  {path: "undefined", component: ChatwindowComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
