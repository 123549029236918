<p>init-chat works!</p>

<!-- Chat icon -->
<app-chat-icon></app-chat-icon>

<!-- Chatbox iFrame -->
<div class="cls-chatbox js-chatbox">
<!--  <iframe src="https://chat.carlocksystems.com/chat" frameborder="0"></iframe>-->
  <iframe src="http://localhost:4200/chat" frameborder="0"></iframe>
</div>
