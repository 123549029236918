<div
  class="action-menu js-saved-responses-menu"
  [class.is-active]="showPreformedTextMenu"
>

  <div
    class="action-menu__overlay js-action-menu-overlay"
    (click)="closeActionMenu()"
  ></div>

  <div class="action-menu__menu">

    <div class="action-menu__group" >

      <div
        class="action-menu__item"
        *ngFor="let preformedText of preformedTexts"
        (click)="setPreformedText(preformedText.id); closeActionMenu()"

      >
        <i class="fas fa-comment"></i>

        <p>
          {{ preformedText.label }}

          <span
            class="preformed-text-{{ preformedText.id }} preformed-text__preview"
            [attr.data-preformed-text-id]="preformedText.id">
          {{ preformedText.text }}
        </span>
        </p>
      </div>


    </div>

    <div class="action-menu__group">

      <div class="action-menu__item js-close-action-menu close" (click)="closeActionMenu()">
        <i class="far fa-times-circle"></i>

        Close menu
      </div>
    </div>
  </div>

</div>

