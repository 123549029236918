<div class="action-menu js-action-menu">

  <div class="action-menu__overlay js-action-menu-overlay"></div>

  <div class="action-menu__menu">

    <!--  Image uploader  -->
    <div class="action-menu__group">
      <div class="action-menu__item js-toggle-file-upload js-close-action-menu">
        <i class="fas fa-upload"></i>

        <p>
          Send file(s)

          <span>PNG, JPEG, JPG, PDF</span>
        </p>
      </div>

      <!--  Screensharing  -->
<!--      <div class="action-menu__item js-close-action-menu" id="start_capture" (click)="startCapture()">-->
<!--        <i class="fas fa-desktop"></i>-->

<!--        <p>-->
<!--          Share screen-->

<!--          <span>Share your screen with us</span>-->
<!--        </p>-->
<!--      </div>-->

      <a *ngIf="checkIfOperator()" class="action-menu__item js-toggle-file-upload js-close-action-menu" href="https://intranet.carlocksystems.com">
        <i class="fas fa-home"></i>

        <p class="action-menu__user-info">
          Back to Intranet

          <span>
            Logged in as

            <span class="username js-username">
            </span>
          </span>
        </p>
      </a>

    </div>

    <div class="action-menu__group">

      <div class="action-menu__item js-close-action-menu close">
        <i class="far fa-times-circle"></i>

        Close menu
      </div>
    </div>
  </div>

</div>


<!-- This one gets activated when the user clicks on the menu item. -->
<div class="image-upload-field js-image-uploader">
  <div class="js-image-upload filedrop">
    <!-- in app.component.html -->
<!--    <ngx-dropzone class="dropzone" [multiple]="false" (change)="onSelect($event); toggleImageUploadButton()" formControlName="images" accept="image/jpeg,image/jpg,image/png,image/gif" id="chatImageUploader">-->
    <ngx-dropzone class="dropzone" [multiple]="false" (change)="onSelect($event); toggleImageUploadButton()" accept="image/jpeg,image/jpg,image/png,application/pdf" id="chatImageUploader">
      <ngx-dropzone-label><span class="is-desktop">Click</span><span class="is-mobile">Tap</span> here to upload a file. <br><span class="is-desktop">You can also drag a (supported) file into this box!</span></ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>({{ f.name }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>

    <div class="flex justify-space-between filedrop__buttons">
      <div class="button button-primary svg-right js-upload-image-button" (click)="onFileUploaded($event)" >
        Use file

        <i class="fas fa-paper-plane"></i>
      </div>

      <div class="button button-secondary js-toggle-file-upload svg-left">
        <i class="far fa-times-circle"></i>
        Close
      </div>
    </div>
  </div>
</div>


<!-- And this one gets activated once the user drags an image/file into the screen. -->
<div class="image-upload-field js-image-uploader-drag">
  <div class="js-image-upload-drag filedrop">
    <!-- in app.component.html -->
    <!--    <ngx-dropzone class="dropzone" [multiple]="false" (change)="onSelect($event); toggleImageUploadButton()" formControlName="images" accept="image/jpeg,image/jpg,image/png,image/gif" id="chatImageUploader">-->
    <ngx-dropzone class="dropzone" [multiple]="false" (change)="onSelect($event); onFileUploaded($event)" accept="image/jpeg,image/jpg,image/png,application/pdf" id="chatImageUploader">
      <ngx-dropzone-label>Drag your (supported) file in here to send!</ngx-dropzone-label>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>({{ f.name }})</ngx-dropzone-label>
      </ngx-dropzone-image-preview>
    </ngx-dropzone>

  </div>

</div>
