import { AfterViewInit, Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  constructor() {
  }

  ngAfterViewInit(): void {
    document.querySelector('.js-loader-default')?.classList?.remove('is-active')
  }


  public ifUserIsSet() {
    if (window.location.hostname == 'localhost') {
      return 17640
    }

    return sessionStorage.getItem('username');
  }

}
