<div class="login__container">

  <div class="block-container">
    <div class="login">
      <h3>
        Operator login
      </h3>

      <form [formGroup]="loginForm" (ngSubmit)="loginOperator()">
        <div class="form-item">
          <label>
            Username:
          </label>

          <input type="text" formControlName="userName">
        </div>

        <div class="form-item">
          <button class="button button-primary" type="submit">
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

