import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable}  from "rxjs";
import { IMessage } from "../../interfaces/Message";
import { IPreformedTexts } from "../../interfaces/PreformedTexts";

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  // For local testing.
  private apiUrlTest: string    = 'https://chat-test.carlocksystems.com:8081/rest-api';

  // For production.
  private apiUrl: string        = window.location.protocol + '//' + window.location.hostname + ':8081/rest-api';

  constructor(private http: HttpClient) {}


  // Load specific message.
  getMessage(conversationId: any): Observable<any> {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.get(this.apiUrlTest + '/v2/conversation?id=' + conversationId);
    } else {
      return this.http.get(this.apiUrl + '/v2/conversation?id=' + conversationId);
    }
  }


  // Load all the messages.
  getMessages(operatorId: any): Observable<any> {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.get(this.apiUrlTest + '/v2/conversationslist?operator_user_id=' + operatorId);
    } else {
      return this.http.get(this.apiUrl + '/v2/conversationslist?operator_user_id=' + operatorId);
    }
  }


  // Add new message to the convo.
  sendMessage(data: IMessage) {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.post(this.apiUrlTest + '/v2/sendmessage/', data);
    } else {
      return this.http.post(this.apiUrl + '/v2/sendmessage/', data);
    }
  }


  sendPhoto(data:any) {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.post(this.apiUrlTest + '/v2/sendphoto', data);
    } else {
      return this.http.post(this.apiUrl + '/v2/sendphoto', data);
    }
  }


  // Load specific message.
  setMessageOnRead(conversationId: any) {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.post(this.apiUrlTest + '/v2/setmessageonread', conversationId);
    } else {
      return this.http.post(this.apiUrl + '/v2/setmessageonread', conversationId);
    }
  }

  // Close the conversation.
  closeConversation(conversation_id: number) {
    if(window.location.hostname == 'localhost' || window.location.hostname == 'chat-test.carlocksystems.com') {
      return this.http.post(this.apiUrlTest + '/v2/closeconversation?id=' + conversation_id, conversation_id).subscribe();
    } else {
      return this.http.post(this.apiUrl + '/v2/closeconversation?id=' + conversation_id, conversation_id).subscribe();
    }
  }

  // Preformed textsssszszs
  getPreformedTexts(language: string): Observable<IPreformedTexts[]> {
    return this.http.get<IPreformedTexts[]>(this.apiUrlTest + '/v2/preformedtexts?lang=' + language);
  }
}
