import { Component, Injectable, input, OnInit, output } from '@angular/core';
import { MessagesService } from "../../services/messages/messages.service";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from "@angular/router";
import { TranslationService } from "../../services/translation/translation.service";
import { SocketService } from "../../services/socket/socket.service";

@Component({
  selector: 'app-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss']
})

@Injectable({
  providedIn: 'root' // just before your class
})
export class MessageInputComponent implements OnInit {

  public files: File[] = [];
  public textInputValidated: boolean = false;
  public isOperator: boolean = false;

  public onStart = output();
  public isLoading = input<boolean>(false);

  // Message form fields
  messageForm = new UntypedFormGroup({
    message: new UntypedFormControl(''),
    username: new UntypedFormControl(''),
    clientNumber: new UntypedFormControl(''),
    autotranslate: new UntypedFormControl(''),
    conversationId: new UntypedFormControl(sessionStorage.getItem('conversation_id'))
  });

  constructor(
    private messagesService: MessagesService,
    private translationService: TranslationService,
    private router: Router,
    private socketService: SocketService
  ) {

  }

  public ngOnInit(): void {
    this.loadUserSettings();
    this.getAllMessages();
    this.checkMessageInputs();
    this.socketService.scrollToBottom();
  }

  protected checkMessageInputs(): void {

    let messageInput = this.messageForm.get('message');
    this.textInputValidated = !!messageInput.value;
  }

  protected checkIfConvoIsActive() {

    let conversationSelected = sessionStorage.getItem('conversation_id');
    return !!conversationSelected;
  }

  protected addNewMessage(event: any) {

    /*
     * This function is for posting and displaying a message (with the possibility to translate it when sent).
     * This also handles the message rendering from all the participants.
     */

    // // 1. Socket.io initialize and other global variables.
    let socket = this.socketService;


    // 2. Function workings when the socket is connected.


    // 3. Set variables.
    let date = new Date();
    let getHours = ('0' + date.getHours()).slice(-2);
    let getMinutes = ('0' + date.getMinutes()).slice(-2);
    let messageValue = this.messageForm.value.message;
    let language = sessionStorage.getItem('language');


    if (this.router.url == '/chat') {

      if (language != 'nl') {
        this.translationService.translateText(this.messageForm.value.message, 'nl').subscribe((response) => {

          let language = sessionStorage.getItem('language');

          /**
           * 4. Below is the body of the message. Attributes can be changed, but make sure that they're
           * always aligned with the corresponding API-call.
           * Notice that the timeout is needed in order for the translation to work.
           */

            // @ts-ignore
          let messageDetails = {
            conversation_id: parseInt(sessionStorage.getItem('conversation_id')),
            user_id: sessionStorage.getItem('user_id'),
            message: this.messageForm.value.message,
            text: this.messageForm.value.message,
            username: sessionStorage.getItem('username'),
            company_name: sessionStorage.getItem('company_name'),
            timePosted: (getHours + ":" + getMinutes),
            // @ts-ignore
            language: language,
            // @ts-ignore
            translated_text: response.data.translations[0].translatedText,
            subject: sessionStorage.getItem('order_id'),
            operator: sessionStorage.getItem('operator_id')
          }

          // 5. Emit the message through Socket.io if the message is not empty.
          if (messageValue) {
            socket.addMessage(messageDetails);

            this.socketService.displayMessage(messageDetails);

            // 6. POST-call naar de BE.
            this.messagesService.sendMessage(messageDetails).subscribe((data) => {

              setTimeout(() => {
                this.socketService.scrollToBottom();
              }, 350);
            });


            // 7. Reset the form.
            this.messageForm.reset();


          } else {
            event.preventDefault();
          }
        });
      } else {

        /**
         * This part of the code is executed only if the client is a Dutch customer.
         * No translation is needed then.
         */

        let messageDetails = {
          conversation_id: parseInt(sessionStorage.getItem('conversation_id')),
          user_id: sessionStorage.getItem('user_id'),
          message: this.messageForm.value.message,
          text: this.messageForm.value.message,
          username: sessionStorage.getItem('username'),
          company_name: sessionStorage.getItem('company_name'),
          timePosted: (getHours + ":" + getMinutes),
          // @ts-ignore
          language: language,
          // @ts-ignore
          translated_text: this.messageForm.value.message,
          subject: sessionStorage.getItem('order_id'),
          operator: sessionStorage.getItem('operator_id')
        }


        // 5. Emit the message through Socket.io if the message is not empty.
        if (messageValue) {
          socket.addMessage(messageDetails);
          // this.displayMessage(messageDetails);
          this.socketService.displayMessage(messageDetails);

          // 6. POST-call naar de BE.
          this.messagesService.sendMessage(messageDetails).subscribe(data => {

            setTimeout(() => {
              this.socketService.scrollToBottom();
            }, 350);
          });


          // 7. Reset the form.
          this.messageForm.reset();


        } else {
          event.preventDefault();
        }
      }
    }

    // Different settings for the operator side.
    if (this.router.url == '/admin') {

      let language = sessionStorage.getItem('language') ?? 'nl';
      let conversationId = sessionStorage.getItem('conversation_id');

      if (language != 'nl') {
        this.translationService.translateText(this.messageForm.value.message, language).subscribe((response) => {


          /*
         * 4. Below is the body of the message. Attributes can be changed, but make sure that they're
         * always aligned with the corresponding API-call.
         * Notice that the timeout is needed in order for the translation to work.
         */

          // @ts-ignore
          let translatedMessage = response.data.translations[0].translatedText;

          let messageDetails = {
            conversation_id: parseInt(sessionStorage.getItem('conversation_id')),
            user_id: sessionStorage.getItem('user_id'),
            message: this.messageForm.value.message,
            text: this.messageForm.value.message,
            username: sessionStorage.getItem('operator_name'),
            timePosted: (getHours + ":" + getMinutes),
            // @ts-ignore
            language: language,
            // @ts-ignore
            translated_text: response.data.translations[0].translatedText,
            operator: sessionStorage.getItem('operator')
          }

          // 5. Emit the message through Socket.io if the message is not empty.
          if (messageValue) {
            socket.addMessage(messageDetails);
            this.socketService.displayMessage(messageDetails);

            // Also set the new message on unread on send.
            this.messagesService.setMessageOnRead(conversationId);

            // 6. POST-call naar de BE.
            this.messagesService.sendMessage(messageDetails).subscribe((data) => {

              setTimeout(() => {
                this.socketService.scrollToBottom();
              }, 350);
            });


            // 7. Reset the form.
            this.messageForm.reset();


          } else {
            event.preventDefault();
          }
        });
      } else {

        /**
         * OPERATOR-SIDE
         * -------------
         * This part of the code is executed only if the client is a Dutch customer.
         * No translation is needed then.
         */


        let messageDetails = {
          conversation_id: parseInt(sessionStorage.getItem('conversation_id')),
          user_id: sessionStorage.getItem('user_id'),
          message: this.messageForm.value.message,
          text: this.messageForm.value.message,
          username: sessionStorage.getItem('operator_name'),
          timePosted: (getHours + ":" + getMinutes),
          // @ts-ignore
          language: language,
          // @ts-ignore
          translated_text: this.messageForm.value.message,
          operator: sessionStorage.getItem('operator')
        }

        // 5. Emit the message through Socket.io if the message is not empty.
        if (messageValue) {
          socket.addMessage(messageDetails);
          this.socketService.displayMessage(messageDetails);

          // 6. POST-call naar de BE.
          this.messagesService.sendMessage(messageDetails).subscribe((data) => {

            setTimeout(() => {
              this.socketService.scrollToBottom();
            }, 350);
          });


          // 7. Reset the form.
          this.messageForm.reset();


        } else {
          event.preventDefault();
        }
      }
    }

  }

  protected checkIfUserIdSet() {
    let userId = sessionStorage.getItem('username');
    return !!userId;
  }

  protected isUsernameSessionSet() {
    return !!sessionStorage.getItem('operator_id');
  }

  /**
   * SAVED RESPONSES
   */

  protected fillSavedResponseFromChild(savedResponse: string) {

    let message = document.getElementById('message') as HTMLInputElement;

    message.focus();

    this.messageForm.get('message').setValue(savedResponse);
    this.checkMessageInputs();
  }

  protected chatStartClick() {
    this.onStart.emit()
  }

  private getAllMessages(): void {

    // Socket connection settings.
    /*
     * When another user posts a message, the message feed will be refreshed
     * and all the  necessary functions will be reloaded.
     */

    this.socketService.getMessage('receive-message').subscribe((messageDetails) => {

      this.loadUserSettings();

      if (this.router.url === '/chat') {
        this.socketService.displayMessage(messageDetails);
      }

      this.socketService.scrollToBottom();
      this.checkMessageInputs();
      // this.onToggleTranslate();

      setTimeout(() => {
        this.socketService.scrollToBottom();
      }, 500)
    })

  }

  /**
   * USER SPECIFIC SETTINGS.
   */

  private loadUserSettings(): void {

    let user_id = sessionStorage.getItem('user_id');
    let username = sessionStorage.getItem('username');
    let operator = sessionStorage.getItem('operator_name');

    this.messageForm.get('user_id')?.setValue(user_id);
    this.messageForm.get('username')?.setValue(username);

    this.isOperator = !!operator;
  }
}
