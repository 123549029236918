import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-init-chat',
  templateUrl: './init-chat.component.html',
  styleUrls: ['./init-chat.component.scss']
})
export class InitChatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    this.toggleChatbox();
    this.toggleChatIcon();
  }

  public toggleChatbox(): void {

    let chatIcon    = document.querySelector('.js-chat-icon');
    let chatbox     = document.querySelector('.js-chatbox');

    chatIcon.addEventListener('click', () => {
      chatbox.classList.toggle('is-active');
    });
  }

  public toggleChatIcon(): void {

    let chatIcon = document.querySelector('.js-chat-icon');

    chatIcon.addEventListener('click', () => {
      chatIcon.classList.toggle('is-active');
    });
  }

}
