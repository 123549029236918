<div class="operator-dashboard__container">

  <div class="operator-dashboard">
    <div class="operator-dashboard__conversations">

      <div class="operator-dashboard__title">
        <img src="./assets/images/cls.svg" alt="CLS Logo">
      </div>

      <div class="operator-dashboard__conversations js-conversations">
        <!--    Chat messages will appear here dynamically    -->

        <div
          class="operator-dashboard__toggle-conversations button button-secondary"
          (click)="toggleArchivedConversations()"
          [class.is-active]="archivedChatsShown"
        >

          <ng-container *ngIf="archivedChatsShown; else activeChatsShown">
            Show active chats
          </ng-container>

          <ng-template #activeChatsShown>
             Show archived chats
          </ng-template>
        </div>

        <div
          class="conversation js-conversation"
          *ngFor="let conversation of conversations"
          attr.data-subject-id="{{ conversation.subject }}"
          attr.data-conversation-id="{{ conversation.id }}"
          attr.data-language="{{ conversation.language }}"
          attr.data-unread_message="{{ conversation.unread_message }}"
          (click)="this.removeNewMessageClass(conversation.id); this.setMessageOnRead(conversation.id); this.activateRightConversation(conversation.id);"
          [class.is-closed]="conversation.is_closed == 1"
          [ngClass]="{'has-phone-number': conversation.phonenumber}"

          (contextmenu)="toggleDeleteClass(conversation.id)"
          oncontextmenu="return false;"
        >
          <ng-container *ngIf="!conversation.phonenumber">
            <div class="conversation__flag">
              <div class="fi-{{ conversation.language }} fis"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="conversation.phonenumber">
            <div class="conversation__flag has-phone-number">
              <i class="fab fa-whatsapp"></i>
            </div>
          </ng-container>


          <div class="conversation__details">

            <div class="flex flex-wrap">
              <div class="conversation__client-name">
                {{ conversation.company_name }}
              </div>

              <span class="conversation__last-message">
                {{ conversation.last_message }}
              </span>
              <span class="conversation__last-message" style="font-style: italic;">
                {{ conversation.last_message_date_formated }}
              </span>

            </div>

            <div class="conversation__new_message js-new-message">
              <i class="fas fa-comment"></i>
            </div>
          </div>

          <!--     Delete button for the convoooo.     -->
          <div
            *ngIf="!archivedChatsShown"
            class="conversation__delete js-delete-conversation"
            (click)="this.closeConversation(conversation.id)">
            <i class="fas fa-archive"></i>
          </div>
        </div>

      </div>
    </div>

    <div class="operator-dashboard__active-convo" id="activeConversation">

      <div class="active-conversation">

        <div class="active-conversation__head" id="chatWindowHead">
          <div class="active-conversation__client-name">
            <h3>
              {{ chatTitle }}
            </h3>
          </div>

          <div class="active-conversation__client-number js-client-number span--dark">
            {{ chatSubTitle }}
          </div>

        </div>

        <div class="active-conversation__chat" id="chatWindow">

          <!-- Loading animation -->
          <div class="conversation--loading js-conversation-loading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M222.7 32.15C227.7 49.08 218.1 66.9 201.1 71.94C121.8 95.55 64 169.1 64 255.1C64 362 149.1 447.1 256 447.1C362 447.1 448 362 448 255.1C448 169.1 390.2 95.55 310.9 71.94C293.9 66.9 284.3 49.08 289.3 32.15C294.4 15.21 312.2 5.562 329.1 10.6C434.9 42.07 512 139.1 512 255.1C512 397.4 397.4 511.1 256 511.1C114.6 511.1 0 397.4 0 255.1C0 139.1 77.15 42.07 182.9 10.6C199.8 5.562 217.6 15.21 222.7 32.15V32.15z"/></svg>

          </div>

          <!-- Show this when there are no messages selected. -->
          <div class="messages__no-messages" [ngClass]="{'but-now-it-has': ifMessageIsSelected()}" >
            <span class="span--dark">
              Select a conversation on the left side to chat.
            </span>
          </div>

          <div class="messages__loop js-messages-loop is-operator-view" id="messages-loop">
            <!-- Messages appear here dynamically. -->

            <div
              class="message js-message"
              *ngFor="let operatorMessage of operatorMessages"
              attr.data-user-id="{{ operatorMessage.username }}"
              attr.data-conversation-id="{{ operatorMessage.conversation_id }}"
              [ngClass]="{ 'has-photo': operatorMessage.is_photo == 1}"
              [class.is-current-user]="operatorMessage.username == matchMessageWithUser()"
              [class.has-pdf]="operatorMessage.text.includes('data:application/pdf')"
              attr.date="{{ operatorMessage.sent.substr(0, 10) | date:'dd-MM-YYYY' }}"
            >
              <div class="message__username js-message-username">
                {{ operatorMessage.username }}
              </div>

              <p>

                <span class="translated">
                  {{ operatorMessage.translated_text }}
                </span>

                <span class="js-message-content message__content"
                      [ngClass]="{'has-image': operatorMessage.is_photo == 1}"
                >

                  <span *ngIf="operatorMessage.is_photo == 1">

                    <div *ngIf="operatorMessage.text.includes('data:application/pdf')" class="span--sent-file message-pdf">

                      <div class="message-pdf__icon">
                        <i class="fas fa-file-pdf"></i>
                      </div>

                      <div class="message-pdf__content">
                        {{ operatorMessage.username }} sent a PDF-file.

                        <a [attr.href]="operatorMessage.text | safe" download>
                          Click here to download it.
                        </a>
                      </div>
                    </div>

                    <img [src]="operatorMessage.text | safe" class="message__image js-message-image" (click)="this.enlargeImage($event)">
                  </span>

                  <span class="message--original">
                    {{ operatorMessage.text }}
                  </span>
                </span>
              </p>

              <small class="time js-time">
                {{ operatorMessage.sent_time.substr(0,5) }}
              </small>
            </div>

            <div class="messages-loop__scrolldown" (click)="this.scrollToBottom()">
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>
        </div>

        <div class="active-conversation__message-input" id="chatWindowMessageInput">
          <app-message-input></app-message-input>

          <app-action-menu></app-action-menu>
        </div>
      </div>
    </div>
  </div>
</div>
