import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { LoginService } from "../../services/login/login.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  loginForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    clientNumber: new UntypedFormControl('')
  })

  ngOnInit(): void {}

  public login(): void {

    setTimeout(() => {
      let reformattedUserId = this.loginForm.get('userName')?.value;
      let username = sessionStorage.getItem('username');

      sessionStorage.setItem('user_id', reformattedUserId.toLowerCase());
      sessionStorage.setItem('username', username);

      // Create a conversation object
      this.loginService.createConversation(username).subscribe(data => {


        // @ts-ignore
        sessionStorage.setItem('username', data.Conversation.customer_user_id);
        // @ts-ignore
        sessionStorage.setItem('company_name', data.Conversation.customer_company_name);
        // @ts-ignore
        sessionStorage.setItem('order_id', data.Conversation.order_id);
        // @ts-ignore
        sessionStorage.setItem('conversation_id', data.Conversation.id);
      });

      setTimeout(() => {
        this.router.navigate(['chat']);
      }, 250);
    }, 500);

  }

  public getCookie(cname: any) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public loginOperator(): void {

    let operatorName = this.loginForm.value.userName;

    sessionStorage.setItem('user_id', operatorName);
    sessionStorage.setItem('username', 'Richinel');
    sessionStorage.setItem('operator', operatorName);
    sessionStorage.setItem('operator_name', 'Richinel');

   this.router.navigate(['admin']);

  }

}
