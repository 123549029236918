<div class="messages block block--primary">
  <!-- Messages component goes here. -->

<!--  <div class="header">-->
<!--    <h3>CLS chatbox by RJ</h3>-->
<!--  </div>-->

  <div class="messages__loop js-messages-loop is-client-view" id="messages-loop">

    <div class="message info">
      <small class="message__username js-message-username">
        ClS Chatbot
      </small>

      <p>
        Welcome to the livechat from Car Lock Systems!
      </p>
    </div>


    <div
      class="message js-message"
      *ngFor="let message of messages"
      attr.data-conversation-id="{{ message.conversation_id }}"
      attr.data-user-id="{{ message.username }}"
      [ngClass]="{'is-current-user': message.is_current_user, 'has-photo': message.is_photo == 1}"
      [class.has-pdf]="message.text.includes('data:application/pdf')"
    >

      <small  *ngIf="message.username" class="message__username js-message-username">
        {{ message.username }}

      </small>


      <p>

        <span class="translated">
          {{ message.translated_text }}
        </span>

        <span class="js-message-content">
          <span class="message--original">
            {{ message.text }}
          </span>
        </span>
      </p>

      <span *ngIf="message.is_photo == 1">
          <div *ngIf="message.text.includes('data:application/pdf')" class="span--sent-file message-pdf">
            <div class="message-pdf__icon">
              <i class="fas fa-file-pdf"></i>
            </div>

            <div class="message-pdf__content">
              {{ message.username }} sent a PDF-file.

              <a [attr.href]="message.text | safe" download>
                Click here to download it.
              </a>
            </div>
          </div>

          <img [src]="message.text | safe" class="message__image js-message-image" (click)="this.enlargeImage($event)">
        </span>

      <small class="time js-time">
        {{ message.sent_time.substring(0, 5) }}
      </small>
    </div>

  </div>

  <div
    class="notification__overlay"
    [ngClass]="{'is-hidden': this.operatorIsAvailable}"
  >
    <div class="notification">

      <div class="notification__body">
        <h4>
          Connection lost
        </h4>

        <p>
          The connection with the operator has been interrupted.
        </p>
      </div>

      <div class="notification__buttons">
        <div (click)="login()" class="notification__button full-width primary cursor-pointer">
          Start a new conversation
        </div>
      </div>
    </div>
  </div>

</div>


<!-- Include message input component. -->
<app-message-input [isLoading]="isLoadingChat" (onStart)="login()"></app-message-input>

<!-- Include action menu. -->
<app-action-menu></app-action-menu>

<!-- Include notice for when a employee is not available. -->
<div class="loader js-loader-notice is-notice" [class.is-active]="!operatorIsAvailable">

  <div class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64.03 239.1c0 49.59 21.38 94.1 56.97 130.7c-12.5 50.39-54.31 95.3-54.81 95.8c-2.187 2.297-2.781 5.703-1.5 8.703c1.312 3 4.125 4.797 7.312 4.797c66.31 0 116-31.8 140.6-51.41c32.72 12.31 69.02 19.41 107.4 19.41c37.39 0 72.78-6.663 104.8-18.36L82.93 161.7C70.81 185.9 64.03 212.3 64.03 239.1zM630.8 469.1l-118.1-92.59C551.1 340 576 292.4 576 240c0-114.9-114.6-207.1-255.1-207.1c-67.74 0-129.1 21.55-174.9 56.47L38.81 5.117C28.21-3.154 13.16-1.096 5.115 9.19C-3.072 19.63-1.249 34.72 9.188 42.89l591.1 463.1c10.5 8.203 25.57 6.333 33.7-4.073C643.1 492.4 641.2 477.3 630.8 469.1z"/></svg>
  </div>

  <p class="translate-not-available">Sorry, there is currently no employee available to talk to. Please try again later.</p>
</div>
