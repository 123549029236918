import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SocketService {

  public socket:  any;
  readonly uri:   string =  window.location.protocol + '//' + window.location.hostname + ':3001';

  public message$:          BehaviorSubject<any> = new BehaviorSubject('');


  constructor() {
    console.log(this.uri)
    this.socket = io(this.uri, {transports: ['websocket']});
  }


  addMessage(message) {
    this.socket.emit('add-message', message);

    // console.log(message);
    this.socket.reconnect;
  }


  getMessage = (eventName: string) => {
    this.socket.on(eventName, (message) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };


  emit(handler: string, event: any) {
    this.socket.emit(handler, event);
  }


  on = (eventName: string) => {
    this.socket.on(eventName, (message) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };


  displayMessage(messageDetails: any): void {

    /*
     * This function contains the logic for creating a div-element on the page
     * containing the content of a message that has been posted by a user.
     * Below you will find the template code. This can be altered, if needed.
     */

    //Set the time for the messages:
    let date            = new Date();
    let getHours        = ('0' + date.getHours()).slice(-2);
    let getMinutes      = ('0' + date.getMinutes()).slice(-2);

    let div = document.createElement('div');
    div.classList.add('message', 'js-message', 'is-from-socket');

    // Add PDF class. Used for styling.
    if(messageDetails.base64) {
      if(messageDetails.base64.includes('data:application/pdf')) {
        div.classList.add('has-pdf', 'message-pdf');
      } else {
        div.classList.add('has-image');
      }
    } else {
      div.classList.add('has-no-file');
    }

    // Add an active class to the message so that you can see what messages belong to you.
    if (messageDetails.user_id == sessionStorage.getItem('user_id') || messageDetails.username == sessionStorage.getItem('username')) {
      div.classList.add('is-current-user');
    }

    // Check for when an image is present. Used for SCSS styling.
    let imageHasValue;

    if (messageDetails.base64) {
      imageHasValue = 'has-image';
    } else {
      imageHasValue = 'has-no-image';
    }


    // Also check if the message variable is filled. Also used for SCSS styling.
    let messageHasValue;

    if (messageDetails.text) {
      messageHasValue = 'has-message';
    } else {
      messageHasValue = 'has-no-message';
    }

    // Check if the client is trying to initiate a video-call.


    // Add the conversation ID to the corresponding div's.
    div.setAttribute('data-conversation-id', messageDetails.conversation_id);
    div.setAttribute('data-username', messageDetails.username);


    /**
     * Append message to the operator's side as well alongside a new conversation.
     * THIS PIECE OF CODE IS ONLY APPLIED TO THE OPERATOR'S DASHBOARD.
     */

    let languageCode    = sessionStorage.getItem('language');
    let getUserSession  = sessionStorage.getItem('username');
    // let getUserCookie   = sessionStorage

    if(messageDetails.operator_user_id == getUserSession) {
      if (document.querySelector('.js-conversations')) {

        let operatorConversations = document.querySelector('.js-conversations');

        // Template of the conversation item (Will be created in the operator dashboard).



        let conversationItem =
          `
        <div
            class="conversation js-conversation"
            data-conversation-id="${ messageDetails.conversation_id }"
            data-client-name="${ messageDetails.user_id }"
            data-operator-id="${ sessionStorage.getItem('operator_id') }"
        >

          <div class="conversation__flag">
            <div class="fi-${languageCode} fis"></div>
          </div>

          <div class="conversation__details">
            <div class="conversation__client-name">
              ${ messageDetails.user_id }
            </div>

            <span class="conversation__last-message">
                ${ messageDetails.translated_text }
            </span>

            <div class="conversation__delete-message js-delete-conversation">
               <i class="fas fa-trash"></i>
            </div>
          </div>
        </div>
        `

        // Show message in the operator dashboard.
        if ( !operatorConversations.querySelector(`.conversation[data-conversation-id="${messageDetails.conversation_id}"] `) ) {
          operatorConversations.innerHTML += conversationItem;
        }
      }
    }

    // Template of the message below.
    div.innerHTML += `

          <small class="message__username js-message-username">
            ${ messageDetails.username }
          </small>
          <p>

                <span class="translated">
                ${messageDetails.translated_text}
</span>


          <span class="${messageHasValue} js-message-content">
            <span class="message--original is-hidden">${messageDetails.text}</span>
          </span>

          <span>

            <div class="message-pdf__icon">
              <i class="fas fa-file-pdf"></i>
            </div>

           <div class="message-pdf__content">
                ${messageDetails.username} sent a PDF-file.

                <a href="${messageDetails.base64}" class="pdf-is-from-socket" download>
                    Click here to download it.
                </a>
            </div>
          </span>

           <span class="${imageHasValue}">
              <img src="${messageDetails.base64}" class="message__image js-message-image image-is-from-socket" />

           </span>

</span>
          </p>

          <small class="time">
            ${ getHours + ":" + getMinutes }
          </small>

          `;


    // Lastly, append the new div to the message feed.
    if (document.querySelector('.js-messages-loop.is-client-view')) {
      if( messageDetails.conversation_id == sessionStorage.getItem('conversation_id') ) {
        document.querySelector('.js-messages-loop.is-client-view')?.appendChild(div);
      }
    } else {
      document.querySelector('.js-messages-loop')?.appendChild(div);
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 150);

  }

    public displayMessageOperator(messageDetails: any): void {

    /**
     * This function contains the logic for creating a div-element on the page
     * containing the content of a message that has been posted by a user.
     * Below you will find the template code. This can be altered, if needed.
     *
     * This is basically the same function as the one above.
     * but this is specifically for the operator side.
     * Same same, but different.
     */

    //Set the time for the messages:
    let date            = new Date();
    let getHours        = ('0' + date.getHours()).slice(-2);
    let getMinutes      = ('0' + date.getMinutes()).slice(-2);

    let div = document.createElement('div');
    div.classList.add('message', 'js-message', 'is-from-socket');

      // Add PDF class. Used for styling.
      if(messageDetails.base64) {
        if(messageDetails.base64.includes('data:application/pdf')) {
          div.classList.add('has-pdf', 'message-pdf');
        } else {
          div.classList.add('has-image');
        }
      } else {
        div.classList.add('has-no-file');
      }

    // // Add an active class to the message so that you can see what messages belong to you.
    if (messageDetails.user_id == sessionStorage.getItem('user_id') || messageDetails.username == sessionStorage.getItem('user_id')) {
      div.classList.add('is-current-user');
    }

    // Check for when an image is present. Used for SCSS styling.
    let imageHasValue;

    if (messageDetails.base64) {
      imageHasValue = 'has-image';
    } else {
      imageHasValue = 'has-no-image';
    }

    // Also check if the message variable is filled. Also used for SCSS styling.
    let messageHasValue;

    if (messageDetails.text) {
      messageHasValue = 'has-message';
    } else {
      messageHasValue = 'has-no-message';
    }

    // // Add the conversation ID to the corresponding div's.
    div.setAttribute('data-conversation-id', messageDetails.conversation_id);
    div.setAttribute('data-username', messageDetails.username);


    /**
     * Append message to the operator's side as well alongside a new conversation.
     * THIS PIECE OF CODE IS ONLY APPLIED TO THE OPERATOR'S DASHBOARD.
     */

    // Add the translation for the operator's dashboard.
    if(messageDetails.base64) {
      // Template of the message below.
      div.innerHTML += `

          <small class="message__username js-message-username">
            ${ messageDetails.username }
          </small>

          <p>

          <span class="${messageHasValue} js-
          message-content message__content">
            <span class="message--original">${messageDetails.text}</span>

          </span>

         <span>

            <div class="message-pdf__icon">
              <i class="fas fa-file-pdf"></i>
            </div>

           <div class="message-pdf__content">
                ${messageDetails.username} sent a PDF-file.

                <a href="${messageDetails.base64}" class="pdf-is-from-socket" download>
                    Click here to download it.
                </a>
            </div>
          </span>

           <span class="${imageHasValue}">
              <img src="${messageDetails.base64}" class="message__image js-message-image image-is-from-socket" />
           </span>
          </p>

          <small class="time">
            ${ getHours + ':' + getMinutes }
          </small>

          `;
    } else {

        // Template of the message below.
        div.innerHTML += `

          <small class="message__username js-message-username">
            ${ messageDetails.username }
          </small>

          <p>

          <span class="translated">
          ${messageDetails.translated_text}
</span>

          <span class="${messageHasValue} js-message-content message__content">
            <span class="message--original">${messageDetails.text}</span>

          </span>

           <span class="${imageHasValue}">
              <img src="${messageDetails.base64}" class="message__image js-message-image" />

           </span>
          </p>

          <small class="time">
            ${ getHours + ':' + getMinutes }
          </small>

          `;
    }





    // Lastly, append the new div to the message feed.
    if (document.querySelector('.js-messages-loop.is-operator-view')) {
      if( messageDetails.conversation_id == sessionStorage.getItem('conversation_id') ) {
        document.querySelector('.js-messages-loop.is-operator-view')?.appendChild(div);
      }
    } else {
      document.querySelector('.js-messages-loop')?.appendChild(div);
    }


    setTimeout(() => {
      this.scrollToBottom();
    }, 150);
  }

  scrollToBottom(): void {

    /**
     * Scroll to the bottom of the message feed. Multi-purpose.
     */

    let bottom = document.getElementById('messages-loop')!;

    bottom.scrollTop = bottom.scrollHeight;
  }

}
