import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  // API settings (may have to be changed depending on the production settings).
  apiUrl:         string  = 'https://translation.googleapis.com/language/translate/v2?key=';
  apiKey:         string  = 'AIzaSyD_uLdzVRYn5V2ZDz6OX_tHelU3CfxvYgw';

  constructor(
    private http: HttpClient
  ) { }

  translateText(messageObject:any, language:string) {

    return this.http.post(this.apiUrl + this.apiKey,
      {
      "q": messageObject,
      "target": language,
      "format": "text"
    });
  }

  translateTextToClient(message: any, language: string) {

    return this.http.post(this.apiUrl + this.apiKey,
      {
        "q": message,
        "target": language,
        "format": "text"
      });


  }
}
